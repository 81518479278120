import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { PreLoader } from './components/PreLoader';
import { Main } from './components/Main';

function App() {
	const [preLoader, setPreLoader] = useState(true);

	const onPreLoaderEnd = () => {
		console.log('here');
		setPreLoader(false);
	};

	return (
		<div className="App">
			{preLoader ? (
				<PreLoader onPreLoaderEnd={onPreLoaderEnd}></PreLoader>
			) : (
				<div>
					<Main />
				</div>
			)}
		</div>
	);
}

export default App;

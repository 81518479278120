// import DrivingStayHard from '../assets/driving-stay-hard.mp4';
import StayFuckingHardPreLoader from '../assets/stay-fucking-hard-preloader-2.gif';
import GogginsSpinner from '../assets/goggins.png';

type PreLoaderProps = {
	onPreLoaderEnd: () => void;
};

export function PreLoader({ onPreLoaderEnd }: PreLoaderProps) {
	return (
		<div className="preLoader fixed inset-0">
			<div className="overlay"></div>

			<img
				src={StayFuckingHardPreLoader}
				alt="Stay Fucking Hard"
				className="preLoader"
			></img>

			{/* <video
				src={DrivingStayHard}
				loop={false}
				autoPlay
				controls={false}
				onEnded={onPreLoaderEnd}
			></video> */}

			<div className="absolute inset-0 flex justify-center items-center z-20">
				<button onClick={onPreLoaderEnd}>
					<img
						src={GogginsSpinner}
						alt="Loading..."
						className="w-8 h-8 spinner"
					/>
				</button>
			</div>
		</div>
	);
}

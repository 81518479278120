import RunningStayHard from '../assets/running-stay-hard-2.gif';
import BeforeAfterGoggin from '../assets/before-after-goggin.png';
import TheyDontKnowMeSon from '../assets/they-dont-know-me-son.gif';
import StayFuckingHard from '../assets/stay-fucking-hard.gif';
import ImBackMotherfucker from '../assets/im-back-motherfucker.gif';
import DoYouStayHard from '../assets/do-you-stay-hard.gif';
import UFC from '../assets/ufc.gif';
import PresealToSeal from '../assets/preseal-to-seal.jpg';
import CarryingLogs from '../assets/carrying-logs.jpg';

export function Main() {
	return (
		<div>
			<div className="main section">
				<div className="overlay"></div>
				<img
					src={RunningStayHard}
					alt="Running Stay Hard"
					className="preLoader"
				></img>
				{/* <video
					src={RunningStayHard}
					loop
					muted
					autoPlay
					controls={false}
				></video> */}
				<div className="content-container absolute inset-0 flex flex-col justify-center items-center p-4">
					<h1 className="text-white text-6xl mb-4 glow-text">
						DAVID GOGGINS - $RUN
					</h1>
					<p className="text-white text-2xl mb-4">
						DEV WILL BE RUNNING LIVE ON STREAM
					</p>
					<p className="text-white text-2xl mb-4">stay fucking hard</p>
					<div className="buttons flex">
						<a
							href="https://twitter.com/DavidGogginsRun"
							target="_blank"
							rel="noreferrer"
							className="btn text-white py-2 px-4 m-2 bg-black bg-opacity-50 rounded"
						>
							twitter
						</a>
						<a
							href="https://t.me/davidgogginsrun"
							target="_blank"
							rel="noreferrer"
							className="btn text-white py-2 px-4 m-2 bg-black bg-opacity-50 rounded"
						>
							telegram
						</a>
						<a
							href="#buy-now"
							// className="btn text-white py-2 px-4 m-2 bg-black bg-opacity-50 rounded"
							className="btn text-white py-2 px-4 m-2 bg-black bg-opacity-50 rounded disabled-link"
							onClick={(e) => e.preventDefault()}
							aria-disabled="true"
						>
							coming soon
						</a>
					</div>
				</div>
			</div>

			<div className="about section fixed-background">
				<div className="flex justify-center items-center overflow-hidden p-5 h-1/2">
					<div className="flex flex-row justify-center items-center gap-10 max-w-4xl mx-auto">
						<div className="text-white text-xl space-y-2">
							<h2 className="text-2xl font-bold">Profile</h2>
							<div className="grid grid-cols-[auto_minmax(0,1fr)] gap-x-4 text-left">
								<span className="font-semibold">Born:</span>
								<span>February 17, 1975 (age 48), Buffalo, New York, U.S.</span>
								<span className="font-semibold">Known for:</span>
								<span>Motivational speaking</span>
								<span className="font-semibold">Height:</span>
								<span>6 ft 1 in (185 cm)</span>
								<span className="font-semibold">Weight:</span>
								<span>200 lb (91 kg)</span>
								<span className="font-semibold">Sport:</span>
								<span>Ultra-distance cycling, Triathlon, Ultramarathon</span>
								<span className="font-semibold">Military Career:</span>
								<span className="flex flex-col space-y-1">
									{' '}
									{/* Removed nested div */}
									<span>Allegiance: United States</span>
									<span>
										Service/Branch: United States Air Force, United States Navy
									</span>
									<span>
										Years of Service: 1994–1999 (USAF), 2001–2015 (USN)
									</span>
									<span>Rank: Chief Petty Officer</span>
									<span>Unit: SEAL Team 5, SDV Team 1</span>
								</span>{' '}
								{/* End of Military Career details */}
							</div>
						</div>

						<div className="flex-1 max-w-md">
							<img
								src={PresealToSeal}
								alt="PresealToSeal"
								className="w-full h-auto object-cover"
							/>
						</div>
					</div>
				</div>
			</div>

			<div className="token section fixed-background">
				<div className="flex justify-center items-center overflow-hidden p-5 h-1/2">
					<div className="flex flex-row justify-center items-center gap-10 max-w-4xl mx-auto">
						<div className="text-white text-xl space-y-2">
							<h2 className="text-2xl font-bold">$RUN Tokenomics</h2>
							<div className="grid grid-cols-[auto_minmax(0,1fr)] gap-x-4 text-left">
								<ul className="list-disc list-inside space-y-2">
									<li>1,000,000,000 $RUN supply</li>
									<li>No tax on transactions</li>
									<li>5% for community incentivies</li>
									<li>5% for development</li>
									<li>90% for liquidity</li>
								</ul>
							</div>
						</div>

						<div className="flex-1 max-w-md">
							<img
								src={CarryingLogs}
								alt="CarryingLogs"
								className="w-full h-auto object-cover"
							/>
						</div>
					</div>
				</div>
			</div>

			<div className="meme section fixed-background">
				<div className="grid grid-cols-2 gap-2 mb-4 place-items-center">
					<img src={TheyDontKnowMeSon} alt="Before and After"></img>
					<img src={StayFuckingHard} alt="Before and After"></img>
					<img src={ImBackMotherfucker} alt="Before and After"></img>
					<img src={DoYouStayHard} alt="Before and After"></img>
					<img src={UFC} alt="Before and After"></img>
					<img
						src={PresealToSeal}
						alt="Before and After"
						className="h-72 object-contain"
					></img>
				</div>
			</div>
		</div>
	);
}
